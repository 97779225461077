<template>
  <ui-component-modal
    :modalTitle="$t('Components.Vouchers.ModalEditImage.Main_Title')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="disableSaveButton"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionImage"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="columns is-mobile is-multiline is-vcentered">
        <div class="column is-7">
          <ImageCropper
            ref="imageCropper"
            :aspectRatio="4/3"
            :guides="false"
            :viewMode="3"
            previewContainer=".imagePreview"
            @callbackImageChosen="val => { newImageChosen = val }"
            @errorCallbackInvalid="val => { hasImageError = val }"
            aspectRatioCssClass="is-4by3"
          />
        </div>
        <div class="column is-5">
          <div class="wrapper-previews">
            <div class="imagePreview has-background-grey-light"></div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import voucherProvider from '@/providers/voucher'
import uploadProvider from '@/providers/upload'

const ImageCropper = () => import('@/components/UI/Components/ImageCropper')

export default {
  components: {
    ImageCropper
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      voucherImage: '',
      mVoucher: null,
      locationId: Number(this.$route.params.locationId),
      newImageChosen: false,
      hasImageError: false
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),

    disableSaveButton() {
      return this.isSaving || !this.newImageChosen || this.hasImageError
    },
  },

  created() {
    this.mVoucher = JSON.parse(JSON.stringify(this.voucher))
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    async saveOptionImage() {
      if (this.disableSaveButton) { return }
      this.isSaving = true

      try {
        const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
        let responseImageUpload = await uploadProvider.methods.locationImage(this.locationId, NEW_IMAGE)
        if (responseImageUpload.status === 200) {
          this.mVoucher.Image = responseImageUpload.data
          this.mVoucher.LocationId = this.locationId

          let saveVoucherResponse = await voucherProvider.methods.saveVoucher(this.mVoucher)
          this.isSavingSuccess = true
          this.setVoucher(saveVoucherResponse.data)

          let t = setTimeout(() => {
            this.$emit('voucherEdited', saveVoucherResponse.data)
            this.onClickCancel()
            clearTimeout(t)
          }, 1500)

        }
      }
      catch {
        this.isSaving = false
        this.isSavingError = true
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.wrapper-previews
  display: flex
  flex-grow: 1
  .imagePreview
    overflow: hidden
    aspect-ratio: 4/3
    &:nth-child(1)
      max-width: 80%
      width: 100%
</style>